import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Location } from '@reach/router';
import PropTypes from 'prop-types';
import { Box, SimpleGrid, Heading, Text, Button, Flex, chakra } from '@chakra-ui/react';
import { MotionBox } from '../constants/poseTemplates';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Section from './section';
import { kebabCase, serviceUrls } from '../helpers/functions';
import TexturaFondo from '../../content/images/texturaFondo.png';
import { useMediaQuery } from '@react-hook/media-query';
import { CalendarIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import Button3D from '../components/button3D';
import { getEnPath, useTranslation } from '../helpers/i18n';
const ButtonD = chakra(Button3D)

const Proyecto = ({ title, imgSrc, url, description, fecha, cliente }) => {
  const smallDevice = useMediaQuery('(max-width: 600px)');
  return (
    <Link to={url ? url : ''}>
      <MotionBox
        //variants={animatedItemVar}
         initial={{ scale: 1 }}
        whileHover={smallDevice ? { scale: 1 } : { scale: 1.05 }}
        whileTap={smallDevice ? { scale: 1 } : { scale: 0.95 }}
        style={{ 
          height: '100%', 
          contentVisibility: 'auto',
          containIntrinsicSize: '400px 508px'        
        }}
      
          bg="white"
          height="100%"
          borderRadius="16px"
          overflow="hidden"
          boxShadow="2px 2px 6px rgba(0, 0, 0, 0.3)"
        >
          <GatsbyImage image={getImage(imgSrc)} alt={`${title} en México`} style={{ margin: '0 auto' }} />
          <Flex direction="column" justifyContent="space-between" mr={6} ml={6}>
            <Box overflow="hidden">
              <Heading as="h3" textAlign="center"
                pt={3}
                noOfLines={2}
                minHeight="52px"
                isTruncated
                whiteSpace="normal"
                >
                {title}
              </Heading>
              <Text
                className="descripcion"
                mt={2}
                mb={1}
                textDecoration="none"
                color="black"
                noOfLines={4}
                minHeight="108px"
                isTruncated
                whiteSpace="normal">
                {description}
              </Text>
            </Box>
            <Flex justifyContent="space-between" mb={4} mt={2} >
              {cliente && (
                <Text textAlign="right" color="#4a5568">
                  {cliente}
                </Text>
                )}
              {fecha && (
                <Text textAlign="right" color="#666">
                  <CalendarIcon mt={-1} mr={1}/> {fecha}
                </Text>
                )}
            </Flex>
          </Flex>
        
      </MotionBox>
    </Link>
  );
}
Proyecto.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.object.isRequired,
  description: PropTypes.string,
  fecha: PropTypes.string,
  cliente: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const Proyectos = ({
  title = 'Proyectos',
  category = '',
  categoryDescription = '',
  limit = 300,
  lang = 'es'
}) => {
  const data = useStaticQuery(graphql`query Proyectos {
  proyectos: allMdx(
    filter: {fields: {collection: {eq: "proyectos"}}, frontmatter: {}}
    sort: {order: DESC, fields: [frontmatter___date]}
    limit: 1000
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          description
          fecha
          cliente
          tags
          image {
            childImageSharp {
              gatsbyImageData(width: 802, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
  tags: allMdx(filter: {fields: {collection: {eq: "proyectos"}}}, limit: 2000) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
    }
  }
}
`);
  const tags = data.tags.group;
  let proyectos = category
    ? data.proyectos.edges
        .filter((item) => item.node.frontmatter.tags.indexOf(category) != -1)
        .filter((_v, i) => i < limit)
    : data.proyectos.edges.filter((_v, i) => i < limit);


  return (
    <React.Fragment>
      <Location>
        {({ location }) => {
          const inProjectPage = location.pathname.match(
            /^\/(proyectos|en\/projects)\/?[a-z]*/
          );

          return <>
            {inProjectPage ? (
              <Box
                background={`#000 url(${TexturaFondo}) 50px 30px`}
                backgroundn="linear-gradient(to right, rgb(35, 35, 35), rgb(53, 53, 53))"
              >
                <Box m="0" overflowX="scroll">
                  <Box
                    width={['max-content', 'max-content']}
                    borderRadius={[
                      '0 0 6px 6px',
                      '0 0 6px 6px',
                      '0 0 0 12px',
                    ]}
                    background="#fff"
                    textAlign="center"
                    margin={['0 0 0em auto', '0 0 0 auto']}
                    padding={[0, 0, '0 0 0 6px']}
                    borderTop={[
                      'none',
                      'none',
                      '4px solid var(--accent-color)',
                    ]}
                  >
                    {/* position={["inherit", "inherit", "absolute"]} top="53px" right="0" zIndex="1" */}
                    {tags
                      .sort((a, b) => (a.fieldValue < b.fieldValue ? 1 : -1))
                      .map((tag) => {
                        const active =
                          kebabCase(category) === kebabCase(tag.fieldValue);
                        const tagUrl = lang === 'en' 
                          ? `/en/projects/${kebabCase(useTranslation(tag.fieldValue, lang))}`
                          : `/proyectos/${kebabCase(tag.fieldValue)}`
                        
                        return (
                          <Button
                            as={Link}
                            key={tag.fieldValue}
                            to={tagUrl}
                            size="xs"
                            m="6px 6px"
                            variant={active ? 'solid' : 'outline'}
                            colorScheme={active ? 'azulin' : 'gray'}
                          >
                            {tag.fieldValue === 'Aplicaciones y Videojuegos'
                              ? 'Apps'
                              : tag.fieldValue === 'Video Mapping 3D'
                              ? 'Mapping 3D'
                                : useTranslation(tag.fieldValue, lang)}{' '}
                            {`(${tag.totalCount})`}
                          </Button>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            ) : null}

            <Section
              background={`#222 url(${TexturaFondo})`}
              backgroundNo="linear-gradient(to right, rgb(35, 35, 35), rgb(53, 53, 53))"
              titleElement={inProjectPage ? 'h1' : 'h2'}
              fullWidth={true}
            >
              <Content>
                {(title || category) && (
                <Heading as="h1"
                  margin={inProjectPage ? ['2em auto 0em auto', '-1em auto 0em auto'] : ['1.5em auto 1.5em auto', '-1em auto 2em auto']}
                  maxWidth={inProjectPage ? '37em' : 'auto'}
                  fontSize={['1.4em', '1.5em']}
                  style={{  fontWeight: 'bold', lineHeight: 1.2, color: '#fafafa', textShadow: '1px 1px 1px rgb(255 255 255 / 65%)', letterSpacing: '-0.03rem', display: 'block'}}>
                    {useTranslation(title || category, lang)}
                </Heading>
                )}
                {category && categoryDescription && (
                  <div
                    className="parrafoChico"
                    style={{
                      color: '#cac9c9',
                      marginBottom: '1em',
                      padding: '1.6em',
                    }}
                  >
                    {categoryDescription}
                    <Box textAlign="right" mt="2em" mr="1em">
                      <Link 
                        to={lang === 'en' 
                          ? getEnPath(serviceUrls[kebabCase(category)])
                          : serviceUrls[kebabCase(category)]}
                      >
                        <ButtonD dark={true}>
                          {lang === 'en'
                            ? `View ${useTranslation(category, lang)} Services`
                            : `Ver servicios de ${category}`
                          }
                            &nbsp;→
                          {/* <ChevronRightIcon /> */}
                        </ButtonD>

                        {/* <Button
                          as={Link}
                          colorScheme="gray"
                          variant="ghost"
                          p="1em"
                          size="sm"
                          m="0 auto"
                          style={{ marginTop: '14px' }}
                          rightIcon={<ChevronRightIcon />}
                        >
                          Ver servicios de {category}
                        </Button> */}
                      </Link>
                    </Box>
                  </div>
                )}
                <SimpleGrid
                  columns={[1, 1, 2, 3, 3]}
                  spacing={['40px', '30px', '20px', '20px']}
                  paddingBottom="4rem"
                  // className="visibilityAutoSection"
                  // style={{ 
                  //   contentVisibility: 'auto',
                  //   containIntrinsicSize: '100px 1050px'
                  // }}
                >
                  {proyectos.map((proyecto, i) => {
                    const datos = proyecto.node.frontmatter;
                    const slug = proyecto.node.fields.slug;

                    return (
                      <Proyecto
                        key={`proyecto_${i}`}
                        title={datos.title}
                        url={`/proyectos${slug}`}
                        imgSrc={datos.image.childImageSharp.gatsbyImageData}
                        description={datos.description}
                        fecha={datos.fecha}
                        cliente={datos.cliente}
                      />
                    );
                  })}
                </SimpleGrid>
              </Content>

              {!inProjectPage ? (
                <Box textAlign="center" m="3rem 0 6em">
                  <Button
                    className="btnCta"
                    as={Link}
                    to={
                      category
                        ? lang === 'en' 
                          ? `/en/projects/${kebabCase(useTranslation(category, lang))}`
                          : `/proyectos/${kebabCase(category)}`

                        : lang === 'en' ? '/en/projects' : '/proyectos'
                    }
                    textDecoration="none"
                    colorScheme="azulin"
                    size="lg"
                    rightIcon={<ExternalLinkIcon ml="2"/>}
                    maxWidth="100%"
                    whiteSpace={['pre-wrap', 'no-wrap']}
                  >
                    {lang === 'en' 
                      ? category ? `View more ${useTranslation(category, lang)} projects` : 'View more projects'
                      : <>Ver más proyectos {category ? ` de ${category}` : ''}</>
                    }
                  </Button>
                </Box>
              ) : null}
            </Section>
          </>;
        }}
      </Location>
    </React.Fragment>
  );
};
Proyectos.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  categoryDescription: PropTypes.string,
  limit: PropTypes.number,
  lang: PropTypes.string
};

const Content = styled.div`
  img {
    max-width: 100%;
  }
  h3 {
    color: var(--accent-color-txt);
    font-size: calc(1.2em + 0.2vw);
    line-height: 1.2;
    font-weight: 700;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgb(246, 243, 242) 3px 0px 0px, rgb(246, 243, 242) 2px 0px 0px,
      rgb(246, 243, 242) 1px 0px 0px, rgb(246, 243, 242) -1px 0px 0px,
      rgb(246, 243, 242) -2px 0px 0px, rgb(246, 243, 242) -3px 0px 0px;
    text-size-adjust: 100%;
    padding: 0;
    margin: 24px 0 8px 0;
    text-align: left;
    /* text-shadow: 2px 2px 1px rgba(100, 100, 100, 0.06); */
  }
  .descripcion {
    font-size: calc(0.89em + 0.2vw);
    line-height: calc(1.38em + 0.2vw);
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
  }
  a {
    text-decoration: none;
  }
  .visibilityAutoSection {
    content-visibility: auto;
    contain-intrinsic-size: 100px 1050px; //525px por fila
  }
`;

export default Proyectos;
