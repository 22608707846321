import React from 'react';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const MotionBox = motion(Box)
// export const MotionBox = motion(
//   forwardRef((props, ref) => {
//     const chakraProps = Object.fromEntries(
//       // do not pass framer props to DOM element
//       Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
//     )
//     return <Box ref={ref} {...chakraProps} />
//   }),
// )

export const animatedSectionVar = {
  animate: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
      delayChildren: 0,
    }, },
  initial: {
    opacity: 1,
  },
}
export const animatedItemVar = {
  animate: {
    x: '0%',
    // scale: 1,
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.0,
      delayChildren: 0.0,
      duration: 0.2,
    }, },
  initial: {
    x: '-40%',
    // scale: 1.2,
    opacity: 0,
    // transition: {
    //   when: "afterChildren",
    //   staggerChildren: 0.3,
    // }, 
  },
}
const MotionAnimatedSection = ({props, children}) => (
  <motion.div {...props}  
    // style={{ height: '100%' }}

    animate="animate"
    initial="initial"
    
    variants={animatedSectionVar}
  >
    {children}
  </motion.div>
);
MotionAnimatedSection.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
}

const MotionAnimatedItem = ({props, children}) => (
  <motion.div {...props}  
    animate={{
      // scale: [1.2, 1.2, 1, 1],
      y: [-80, 10, 0],
      x: '0px',
    }}

    initial={{
      x: '0px',
      y: '80px',
      scale: 1.1,
      opacity: 0.8,
    }}
    transition={{ type: 'spring', duration: 0.3, delay: 0}}
    
    // variants={animatedSectionVar}
  >
    {children}
  </motion.div>
); 
MotionAnimatedItem.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
};

export { MotionAnimatedItem,MotionAnimatedSection}