import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { maxContainerWidth } from '../constants/theme';
import { Heading, Box } from '@chakra-ui/react';
import MEDIA from 'helpers/mediaTemplates';
import { useInView } from 'react-intersection-observer';
import { animatedSectionVar, animatedItemVar } from '../constants/poseTemplates';
import { motion } from 'framer-motion';

const Section = ({
  children,
  background = 'transparent',
  color = 'white',
  title,
  titleElement = 'h2',
  contentHeight = 'auto',
  fullWidth = false,
  //centerVerticaly = false,
}) => {
  const [ref, inView] = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  });
  return (
    <motion.div  ref={ref}
      animate={inView ? 'animate' : 'initial'}
      initial="initial"
      variants={animatedSectionVar}>
      <section style={{ background: background }}>
        <Content
          style={{
            minHeight: contentHeight,
            maxWidth: fullWidth ? '2680px' : maxContainerWidth,
          }}
        >
          <motion.div variants={animatedItemVar}>
            {title && (
              <Heading
                as={titleElement}
                size="lg"
                mb="6rem"
                mt="3rem"
                textAlign="center"
                className="sectionHeader"
                style={{ color: color }}
              >
                {title}
              </Heading>
            )}
          </motion.div>
          <Box marginTop={[0, '3em']}>{children}</Box>
        </Content>
      </section>
    </motion.div>
  );
};
Section.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
  background: PropTypes.string,
  title: PropTypes.any,
  contentHeight: PropTypes.any,
  titleElement: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  centerVerticaly: PropTypes.bool,
};

const Content = styled.div`
  margin: auto;
  padding: 2rem 3rem 3em;
  .sectionHeader {
    color: #fff;
    font-size: calc(1.25rem + 1.6vw);
    line-height: 1.2;
    letter-spacing: -0.03rem;
    font-weight: 700;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.65);

    padding: 0;
    /* text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.4); */
    text-shadow: #0000003b -3px 4px 6px;
  }

  ${MEDIA.PHONE`
    padding: 0.1em 1rem;
    .sectionHeader {
      margin-top: 2.5em;
      margin-bottom: 2.5em;
    }
  `};
`;

export default Section;
