import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { chakra } from '@chakra-ui/system';

const Button3D = ({children, size, dark}) => (
  <Container>
  <chakra.button className="pushable"
      fontSize={size == 'lg' ? ['1rem', '1.1rem'] : ['0.9rem', '0.9rem']}
      whiteSpace={['normal', 'nowrap']}
      // margin={['0 6px 12px 6px', '0']}
      marginRight={['8px', '0']}
      marginLeft={['8px', '0']}
      textOverflow="ellipsis"
      textAlign="left"
      // overflow="hidden"
      // justifyContent="flex-end"
      >
    <span className="shadow"></span>
    <span className={dark ? 'edge dark' : 'edge'}></span>
    <span className="front"
        style={{ padding: size == 'lg' ? '10px 24px' : '8px 12px'}}>
      {children}
    </span>
  </chakra.button>
  </Container>
)
Button3D.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  dark: PropTypes.string,
};

const Container = styled.div`
.pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
  }
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
    filter: blur(2px);
  }
  .edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient( to left,
      hsl(0deg 0% 98%) 0%,
      hsl(200deg 56% 36%) 8%,
      hsl(200deg 56% 36%) 92%,
      hsl(0deg 0% 99%) 100% );
    
    filter: blur(8px);
  }
  .dark {
    background: linear-gradient( to left,hsl(0deg 2% 20%) 0%,hsl(200deg 59% 28%) 8%,hsl(200deg 70% 22%) 92%,hsl(0deg 3% 24%) 100% );
    filter: blur(0px);
  }
  .front {
    display: flex;
    position: relative;
    padding: 8px 12px;
    border-radius: 6px;
    /* font-size: 18px; */
    font-weight: bold;
    text-shadow: 1px 1px 1px #333;
    color: white;
    background: hsl(202deg 90% 38%);//hsl(200deg 100% 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  .pushable:hover {
    filter: brightness(110%);
  }
  .pushable:hover .front {
    transform: translateY(-6px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  .pushable:hover .shadow {
    transform: translateY(4px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  .pushable:focus:not(:focus-visible) {
    outline: none;
  }
`;

export default Button3D;